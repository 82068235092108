#details {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, .2);
	z-index: 200;
	cursor: pointer;
	opacity: 0;
	display: none;
	transition: opacity .35s;

	> svg {
		user-select: none;
		position: absolute;
		top: 5vh;
		right: 6vw;
		height: 30px;
		z-index: 404;
		padding: 15px;
		cursor: pointer;
		transform: scale(1);
		transition: transform .2s;

		&:hover {
			transform: scale(1.1);
		}
	}

	> div {
		height: 90vh;
		width: 90vw;
		margin: 5vh 0 0 5vw;
		background: #FFF;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		overflow-y: scroll;
		overflow-x: hidden;
		cursor: default;
		padding: 0 5vw;
		box-sizing: border-box;
		transform: translateY(20%);
		transition: transform .4s;

		border-width: 0;
		border-left-width: 3px;
		border-style: solid;
		border-image: linear-gradient(to top, #8E2DE2, #4A00E0) 1 100%;
		border-radius: 3px;

		> div {
			padding-top: 4vw;
			max-width: 900px;

			h3 {
				font-size: 1.8em;
				margin: 0;
			}

			p {
				font-size: 1em;
				line-height: 1.5;
				margin: 2em 0;
			}

			img, em {
				width: 100%;
				max-width: 800px;
				margin: auto;
				display: block;
				text-align: center;
			}

			em {
				margin-top: .4em;
				color: #545454;
			}

			ul {
				margin: 0;
				list-style: none;
				padding: 0;
				padding-bottom: 6em;

				li {
					padding: .4em 0;

					&:last-child {
						border-bottom: 0;
					}

					a {
						text-decoration: none;
						height: 1.5em;
						display: flex;
						align-items: center;
						color: #202020;

						&:focus {
							outline: none;
						}

						&::-moz-focus-inner { // sass-lint:disable-line no-vendor-prefixes
							border: 0;
						}

						svg {
							height: 1.8em;
							margin-right: .4em;
						}
					}
				}
			}
		}
	}
}
