body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
}

#start {
	position: relative;
	height: 80vh;
	overflow: hidden;

	> div {
		background: #000;
		position: relative;

		video {
			position: absolute;
			height: 80vh;
			width: 142.2vh;
			top: 0;
			opacity: 1;
			transition: opacity .5s ease-out;

			&.hidden {
				opacity: 0;
			}
		}

		@import "preload";

		&:first-of-type {
			height: 100%;
			width: calc(60vw);
			position: relative;

			video {
				left: 50%;
				transform: translateX(-50%);
			}

			&::before {
				@include preleft;

				background-size: cover;
				min-height: 84vh;
				min-width: 64vw;
				top: -2vh;
				left: -3vw;
			}
		}

		&:nth-of-type(2) {
			overflow: hidden;
			position: absolute;
			display: block;
			height: 80vh;
			width: 60vw;
			top: 0;
			right: -10vw;
			clip-path: polygon(10vw 0%, 100% 0%, 100% 100%, 0% 100%);
			z-index: 2;

			video {
				right: 50%;
				transform: translateX(50%);
			}

			&::before {
				@include preright;

				background-size: cover;
				min-height: 84vh;
				min-width: 64vw;
				top: -2vh;
				right: 50%;
				transform: translateX(50%);
			}
		}

		&:nth-of-type(3) {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			background: rgba(0, 0, 0, .25);

			> div {
				position: absolute;
				top: 15vh;
				left: 50vw;
				color: #FFF;
				line-height: 1;

				* {
					margin: 0;
				}

				h2 {
					font-weight: lighter;
					font-size: 6vh;
					text-shadow: 0 0 2vh rgba(0, 0, 0, .6);
				}

				h1 {
					font-size: 18vh;
					margin-left: -1vh;
					margin-top: -1vh;
					text-shadow: 0 0 1.5vh rgba(0, 0, 0, .2);
				}

				h3 {
					font-weight: lighter;
					font-size: 3vh;
					line-height: 1.4;
					padding-left: .5vh;
					max-width: 60vw;
					text-shadow: 0 0 1vh rgba(0, 0, 0, 1);
				}
			}
		}

		&:last-of-type {
			position: absolute;
			bottom: 0;
			width: 100%;
			color: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 4;
			cursor: pointer;
			background: rgba(24, 24, 24, .34);
			padding: 1em 0;
			transition: padding .12s ease-in-out, background .12s ease-in-out;

			> div {
				user-select: none;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				opacity: 1;
				transition: opacity .2s ease-in-out;

				&.hidden {
					opacity: 0;
				}

				> div {
					strong {
						display: block;
						font-size: 1em;
						text-shadow: 0 0 .3vh rgba(0, 0, 0, .8);
					}

					span {
						display: block;
						font-size: .95em;
						text-shadow: 0 0 .2vh rgba(0, 0, 0, .8);
					}
				}

				svg {
					fill: #FFF;
					margin-left: .5em;
					padding: .5em;
					height: 1.5em;
					opacity: .9;
					transform: scale(1);
					transition: transform .2s, opacity .2s;
				}
			}

			&:hover {
				background: rgba(0, 0, 0, .34);
				padding: 1.2em 0 1.4em;

				svg {
					opacity: 1;
					transform: scale(1.05);
				}
			}
		}
	}
}

#projects {
	position: relative;
	overflow: hidden;
	background: #F4F4F4;
	padding-bottom: 50px;

	> span {
		display: block;
		background: linear-gradient(#C8C8C8, #C8C8C8 20%, #F4F4F4);
		position: absolute;
		height: 160vh;
		width: 60vw;
		top: 0;
		right: 50vw;
		z-index: 0;
		clip-path: polygon(0% 0%, 100% 0%, 40vw 100%, 0% 100%);
	}

	> h1 {
		position: relative;
		z-index: 99;
		text-align: center;
		font-weight: normal;
		font-size: 3em;
		padding: 7vh 0 5vh;
		margin: 0;
		color: #8947E3;
	}

	> ul {
		position: relative;
		z-index: 99;
		list-style: none;
		padding: 0 2vw;
		margin: auto;
		width: 100%;
		max-width: 1000px;
		box-sizing: border-box;

		> li {
			position: relative;
			background: #FFF;
			padding: 20px;
			box-sizing: border-box;
			margin-bottom: 30px;
			cursor: pointer;
			box-shadow: -2px 3px 4px 2px rgba(0, 0, 0, .05);
			transform: scale(1);
			transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;

			border-width: 0;
			border-left-width: 3px;
			border-style: solid;
			border-image: linear-gradient(to top, #8E2DE2, #4A00E0) 1 100%;
			border-radius: 2px;

			&:hover {
				box-shadow: -2px 4px 10px 2px rgba(0, 0, 0, .1);
				transform: scale(1.01);
			}

			* {
				pointer-events: none;
			}

			h4 {
				margin: 0;
				font-size: 24px;
				max-width: calc(96vw - 100px);
				line-height: 40px;
				height: 40px;
				font-weight: normal;
			}

			p {
				margin-bottom: 0;
				line-height: 1.35em;

				strong {
					font-weight: normal;
					color: #8947E3;
				}
			}


			svg {
				position: absolute;
				top: 20px;
				right: 20px;
				fill: linear-gradient(#8E2DE2, #4A00E0);
				height: 40px;
			}
		}
	}
}

#footer {
	background: #24242A;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> div:first-child {
		width: 100%;
		display: flex;
		padding: 50px 0;
		justify-content: center;


		> a {
			text-align: center;
			color: #D4D4D4;
			font-size: 30px;
			font-weight: lighter;
			padding: 20px 50px;
			display: block;
			text-decoration: none;

			&:focus {
				outline: none;
			}

			&::-moz-focus-inner { // sass-lint:disable-line no-vendor-prefixes
				border: 0;
			}

			&:first-child {
				border-right: 1px solid #808080;
			}

			svg {
				fill: #D4D4D4;
				display: inline-block;
				height: 72px;
				vertical-align: middle;
			}

			&:hover {
				color: #FFF;

				svg {
					fill: #FFF;
				}
			}
		}
	}

	> div:last-child {
		padding-bottom: 30px;
		color: #44464D;
		font-size: 18px;
		font-weight: lighter;

		a {
			text-decoration: none;
			color: #44464D;
			padding: 10px;

			&:hover {
				color: #5F616C;
			}
		}
	}
}

@import "details";

@media (min-aspect-ratio: 23 / 10), (max-width: 900px) {
	// sass-lint:disable-block no-important

	#start > div:first-of-type {
		width: 100vw;

		> video {
			z-index: 0;
			width: 100vw ;
			height: 300vh;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&::before {
			min-width: 104vw;
		}
	}

	#startRight {
		display: none !important;
	}

	#projects {
		background: #E7E7E7;

		#projectsTriangle {
			display: none !important;
		}
	}

	#start > div:nth-of-type(3) > div {
		left: 20vw;
	}
}

@media (max-width: 900px) {
	// sass-lint:disable-block no-important

	#startLeft > video {
		min-width: 142vh;
	}

	#footer {
		> div:first-child > a {
			font-size: 20px;
			padding: 10px 40px;

			svg {
				height: 42px;
			}
		}

		> div:last-child {
			font-size: 15px;
		}
	}
}

@media (max-width: 575px) {
	// sass-lint:disable-block no-important

	#start {
		> div:nth-of-type(3) > div {
			left: 10vw;
		}

		> div:last-of-type > div > div {
			strong {
				font-size: .8em;
			}

			span {
				font-size: .75em;
			}
		}
	}

	#projects {
		> h1 {
			font-size: 2.6em;
		}

		> ul > li {
			p {
				text-align: left;
			}

			h4 {
				line-height: 1.2;
				height: auto;
			}
		}
	}

	#footer > div:first-child {
		flex-direction: column;

		> a:first-child {
			border: 0;
		}
	}

	#details {
		svg {
			top: 2vw;
			right: 0;
		}

		> div {
			margin: 0;
			height: 100vh;
			width: 100vw;
			border: 0;
		}
	}
}
